.coupon-card {
}

.coupon-card .coupon-details {
  background: #fafcff;
  border-radius: 3px 3px 0px 0px;
  padding: 1.5rem;
  padding-bottom: 5px !important;
  margin: 0;
  width: 100%;
}

.coupon-card .list-section {
  padding: 1.5rem;
}

.coupon-card .coupon-details .header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-bottom: 16px !important;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 16px;
}

.coupon-card .coupon-details .details-row {
}

.coupon-card .coupon-details .header-row h4.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #2e6adf;
  margin: 0;
}

.coupon-card .coupon-details .header-row span.code {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #424040;
  background: #fbe599;
  border-radius: 3px;
  padding: 5px 8px;
}

.coupon-card .coupon-details .details-row .coupon-details-section {
}

.coupon-card .coupon-details .details-row .coupon-details-section .detail-box {
  margin-bottom: 16px;
}

.coupon-card
  .coupon-details
  .details-row
  .coupon-details-section
  .detail-box
  p.sub {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #949191;
  margin-bottom: 8px;
}

.coupon-card
  .coupon-details
  .details-row
  .coupon-details-section
  .detail-box
  p.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #424040;
  margin: 0;
}

.coupon-card .card-body.card-dashboard {
  padding: 0;
}

.coupon-card .list-section h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #1e1e1e;
  margin-bottom: 0;
}

.wrapperClassName {
  border: 1px solid #e1e1e1;
  min-height: 240px;
  max-height: 240px;
  overflow-y: auto;
}
