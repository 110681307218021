.filter-btn {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.btn.btn-secondary {
  color: #313131;
  background: transparent;
  border: 1px solid #313131;
  margin-right: 8px;
  padding: 8px 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 6px;
  max-height: 40px;
}

/* modal-mini */

.modal-header {
  padding: 0 0 15px;
  border-bottom: 1px solid #e1e1e1;
}

.modal-body {
  padding: 30px 0;
}

.modal-mini .modal-footer {
  padding: 0;
  border: none;
}

.modal-content {
  padding: 30px 25px;
}

.modal-mini .modal-footer .btn.btn-primary {
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  max-height: 45px !important;
}

.modal-header .modal-title {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #2e6adf;
}

.modal-header .btn-close {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.modal-header .btn-close:focus {
  border: none;
  outline: none;
}

/* modal large */

.modal-large .modal-content {
}

.modal-large .modal-dialog {
  max-width: 80%;
}

.modal-large .dropzone {
  background: linear-gradient(
    180deg,
    rgba(230, 240, 255, 0.2) 0%,
    rgba(202, 220, 245, 0.2) 100%
  );
  border: 1px dashed #2e6adf;
  padding: 15px;
  cursor: pointer;
}

.modal-large .table-responsive {
  margin-top: 30px;
}

.modal-large .content-empty {
  width: 100%;
  padding: 120px 0;
}

.modal-large .dropzone .flex-centered {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-large .dropzone .flex-centered span {
  background: #2e6adf;
  /* Primary */
  border: 1px solid #2e6adf;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.modal-large .dropzone .flex-centered p {
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  /* or 158% */
  text-align: center;
  color: #777777;
  margin-top: 5px;
  margin-bottom: 0;
}

.modal-large .modal-table {
  width: 100%;
}

.modal-large .modal-table thead tr th {
  background: rgba(46, 106, 223, 0.07);
  padding: 8px 16px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #242424;
  border-color: #2e6adf;
  border-style: solid;
  border-width: 1px 0 1px 0;
}

.modal-large .modal-table tbody tr td {
  padding: 14px 16px;
}

.modal-large .content-empty .fullwidth {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-large .content-empty .fullwidth img {
  margin-bottom: 16px;
}

.modal-large .content-empty .fullwidth p {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: rgba(46, 106, 223, 0.3);
}

.modal-large .modal-table tbody tr td .form-control {
  height: 30px;
  line-height: 30px;
  color: #7d7d7d;
}

.modal-large .modal-table tbody tr td select.form-control {
}

.modal-large .modal-table tbody tr td .progress-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-large .modal-table tbody tr td .progress-block .doc-filename {
  flex: 1;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
}

.modal-large .modal-table tbody tr td .progress-block .delete-link {
}

.modal-large .modal-table tbody tr td .progress-block .doc-filename .sub {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #7d7d7d;
  margin-bottom: 4px;
}

.modal-large
  .modal-table
  tbody
  tr
  td
  .progress-block
  .doc-filename
  .doc-progress {
  position: relative;
  height: 6px;
  display: flex;
  align-items: center;
}

.modal-large
  .modal-table
  tbody
  tr
  td
  .progress-block
  .doc-filename
  .doc-progress
  .progress-scale {
  border-radius: 24px;
  height: 6px;
  width: 100%;
  position: relative;
  margin-bottom: 4px;
}

.progress-scale.progress-success {
}

.progress-scale .progress-percent {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.modal-large .modal-table thead tr th:first-child {
  border-width: 1px 0 1px 1px;
}

.modal-large .modal-table thead tr th:last-child {
  border-width: 1px 1px 1px 0;
}

.modal-large .modal-table tbody tr td input.form-control {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #bbbbbb;
  padding: 0;
}

.modal-large
  .modal-table
  tbody
  tr
  td
  .progress-block
  .doc-filename
  .doc-progress
  .progress-scale:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #ebeff2;
}

.progress-scale.progress-failed .progress-percent {
  background: #ff5050;
  border-radius: 24px;
}

.progress-scale.progress-success .progress-percent {
  background: #2e6adf;
  border-radius: 24px;
}

.modal-large
  .modal-table
  tbody
  tr
  td
  .progress-block
  .doc-filename
  .doc-progress
  > img {
  position: absolute;
  top: -17px;
  right: 0;
}
