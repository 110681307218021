div.dataTables_wrapper div.dataTables_length label {
  margin: 12px 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #555555;
}

div.dataTables_wrapper div.dataTables_filter label {
  margin: 12px 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #555555;
}

div.dataTables_wrapper div.dataTables_length label select.form-control {
  -webkit-appearance: auto;
  padding: 0 15px !important;
  max-height: 40px;
  line-height: 40px;
  font-size: 13px;

  /* identical to box height */
  color: #000000;
  margin: 0 5px;
  box-shadow: 0px 4px 4px rgb(225 225 225 / 25%);
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}

div.dataTables_wrapper div.dataTables_filter label input.form-control {
  padding: 0 15px !important;
  max-height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #000000;
  box-shadow: 0px 4px 4px rgb(225 225 225 / 25%);
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

table.dataTable {
  margin: 0 !important;
  border: 1px solid #f1f1f1 !important;
}

table.dataTable thead tr th {
  padding: 20px 10px !important;
  background: #f8f8f8;
  border: 1px solid #f1f1f1;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #555555;
}

table.dataTable tbody tr td {
  padding: 8px 10px !important;
  background: #ffffff;
  border-right: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
  vertical-align: middle;
}

table.dataTable thead tr th:before,
table.dataTable thead tr th:after {
  bottom: calc(50% - 6px) !important;
}

table.dataTable tfoot tr th {
  padding: 20px 10px;
  background: #f8f8f8;
  border: 1px solid #f1f1f1;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
}

div.dataTables_wrapper div.dataTables_info {
  padding: 0;
  margin: 12px 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 12px 0;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .previous {
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .previous a {
}

div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  li.paginate_button.page-item.active {
}

div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  li.paginate_button.page-item {
  margin: 0 3px;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .next {
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .next a {
}

div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  li.paginate_button.page-item
  a {
  background: #ffffff;
  border: 1px solid #dfe3e8;
  border-radius: 4px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 131% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #999999;
  transition: all 0.3s ease;
}

div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  li.paginate_button.page-item.active
  a,
div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  li.paginate_button.page-item
  a:hover {
  color: #2e6adf;
  border: 1px solid #2e6adf;
  transition: all 0.3s ease;
}

div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  li.paginate_button.page-item.disabled
  a {
  color: #424242;
  border: 1px solid #d1d1d1;
  background: #d1d1d1;
}
table.dataTable thead > tr > th.sorting.text-center {
  padding-right: 10px;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 20px;
}

table.dataTable thead > tr > th.sorting.text-center {
  padding-right: 10px;
}

table.dataTable tbody tr td span {
  padding: 3px;
  border-radius: 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-align: center;
  color: #555555;
}

table.dataTable tbody tr td span.active {
  background: linear-gradient(0deg, #c1e7de, #c1e7de), #eaeaea;
}

table.dataTable tbody tr td .btn.btn-borderonly {
  margin: 4px;
  width: 32px;
  height: 32px;
  padding: 0;
  line-height: 26px;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.3s ease;
}

table.dataTable tbody tr td .edit:hover {
  /*border: 1px solid #2E6ADF;*/
  transition: all 0.3s ease;
}

table.dataTable tbody tr td .delete:hover {
  transition: all 0.3s ease;
  /*border: 1px solid #DA7B7B;*/
}
div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
  align-items: center;
}
body > .dataTable {
  display: none !important;
}
table.dataTable thead tr th {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #313131;
  background: #ebebeb;
  padding: 10px !important;
  border-color: rgb(214, 216, 220);
  border-right: 0;
}
table.dataTable thead tr th:last-child {
  border-right: 1px solid rgb(214, 216, 220) !important;
}

table.dataTable tbody tr td {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #777777;
  padding: 16px 15px !important;
  border: none;
  border-bottom: 1px solid #e2e2e2 !important;
}

table.dataTable tbody tr td .inactive {
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  color: #555555;
  background: rgba(226, 147, 54, 0.6);
  border-radius: 2px;
  padding: 3px 6px;
  min-width: 60px;
  display: inline-block;
  text-align: center;
}

table.dataTable tbody tr td .active {
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  color: #555555;
  background: rgba(54, 226, 123, 0.6);
  border-radius: 2px;
  padding: 3px 6px;
  min-width: 60px;
  display: inline-block;
  text-align: center;
}

table.dataTable tbody tr td .edit {
  display: inline-block;
  padding-right: 12px;
  border-right: 1px solid #dadada;
}

table.dataTable tbody tr td .delete {
  margin-left: 12px;
}
.dataTable .checkbox-block label:before {
  border-radius: 0;
  margin: 0;
}

.dataTable .checkbox-block input:checked + label:after {
  border-radius: inherit;
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 4px;
  width: 5px;
  height: 9px;
  border: solid #2e6adf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.dataTable .checkbox-block input:checked + label:before {
  background: transparent;
  margin: 0;
  padding: 0;
  border: 1px solid #2e6adf;
}
.dataTable .checkbox-block label {
  margin: 0 auto;
}
.no-sorting:before,
.no-sorting:after {
  content: unset !important;
}
th.no-sorting {
  padding: 20px 10px !important;
}
