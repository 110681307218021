.login-wrapper {
  margin-top: -2.5rem !important;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}

.login-wrapper .left-block {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e6adf;
}
.btn-secondary {
  color: #ffffff;
  background-color: #6b6f82;
  border-color: #6b6f82;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #5a5d6d;
  border-color: #545766;
}
.btn-secondary:focus,
.btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(107, 111, 130, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(107, 111, 130, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff;
  background-color: #6b6f82;
  border-color: #6b6f82;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #545766;
  border-color: #4e515f;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(107, 111, 130, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(107, 111, 130, 0.5);
}
.login-wrapper .right-block {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-wrapper .right-block .card {
  width: 448px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin-bottom: 0;
  padding: 32px;
}

.login-wrapper .right-block .card h4.form-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #313131;
  margin-bottom: 24px;
  text-align: center;
}

.login-wrapper .right-block .card .login-form {
  margin-bottom: 0;
}

.login-wrapper .right-block .card .login-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.login-wrapper .right-block .card .login-form label.control-label {
  font-weight: 500;
  font-size: 10.5px;
  line-height: 110%;
  /* identical to box height, or 12px */
  color: #82869a;
}

.login-wrapper .right-block .card .login-form .form-control {
  background: #FFFFFF;
  border: 1px solid #AFAFAF;
  border-radius: 6px;
  padding:0 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #82869a;
}
.customTextField {
  background: #FFFFFF !important;
  border: 1px solid #AFAFAF !important;
  border-radius: 6px !important;
  padding: 0 8px !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 21px !important;
  color: #313131 !important;
  height: 32px;
}
.customTextField::placeholder {
  color: #A9A8A8 !important;
}

.login-wrapper .right-block .card .login-form .checkbox-block {
}

.login-wrapper .right-block .card .login-form .form-group.d-flex {
  align-items: center;
  justify-content: space-between;
}

.login-wrapper .right-block .card .login-form .form-group.d-flex a {
  font-weight: 500;
  font-size: 12.25px;
  line-height: 110%;
  color: #313131;
}

.login-wrapper .right-block .card .login-form .btn.btn-primary {
  background: #000 !important;
  border-radius: 8.75px;
  width: 100%;
  text-shadow: 0px 0.875px 1.75px rgba(0, 0, 0, 0.15);
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */
  text-transform: uppercase;
  color: #ffffff !important;
  padding: 0;
  height: 50px;
  max-height: 50px;
  line-height: 50px !important;
}

.login-wrapper .right-block .card .login-form .form-control::placeholder {
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  color: rgba(130, 134, 154, 0.5);
}

.login-wrapper .right-block .card .login-form .checkbox-block label {
  font-weight: 500;
  font-size: 12.25px;
  line-height: 110%;
  /* identical to box height, or 13px */
  color: rgba(130, 134, 154, 0.6);
}
.login-wrapper .right-block .card .login-form .form-group:last-child {
  margin-bottom: 0;
}
.login-wrapper .right-block .card .login-form .form-group.otp-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -9px 24px;
}

.login-wrapper .right-block .card p.form-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* or 18px */
  color: #a2a2a2;
  margin: 12px 0 24px;
}

.login-wrapper
  .right-block
  .card
  .login-form
  .form-group.otp-block
  .form-control {
  background: #f1f1f1;
  border-radius: 3.19565px;
  flex: 1;
  margin: 0 9px;
  height: 50px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 50px;
  /* identical to box height, or 14px */
  color: #a2a2a2;
}

.login-wrapper
  .right-block
  .card
  .login-form
  .form-group.otp-block
  .form-control::placeholder {
  font-weight: 500;
  font-size: 13px;
  line-height: 50px;
  color: #a2a2a2;
}

.login-wrapper .right-block .card .login-form .form-group a.link {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  color: #050505;
}
.login-wrapper
  .right-block
  .card
  .login-form
  .form-group.otp-block
  label.error {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #ff4545;
  position: relative;
  width: 100%;
  margin: 0;
  text-align: right;
  margin: 10px 0 0;
}

.login-wrapper
  .right-block
  .card
  .login-form
  .form-group.otp-block
  input.form-control.error {
  border: 1px solid #ff4545 !important;
}
.password-icon{   /*  sreelekshmi  */

  position: absolute;
  top: 29px;
  right: 8px;
  cursor: pointer;
}
.loginLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  gap: 8px;
}

.loginLogo h3.brand-text {
  margin: 0;
  font-weight: bold;
  font-size: 36px;
  line-height: 30px;
  text-transform: capitalize;
  color: #3D4865;
  display: block;
  text-align: left;
}