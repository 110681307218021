.card-content.support-card {
}

.card-content.support-card .card-body.card-dashboard {
  padding: 0;
}

.card-content.support-card .contact-details {
  margin: 0;
  width: 100%;
  padding: 1.5rem;
  background: #fafcff;
  margin-bottom: 20px;
}

.card-content.support-card .contact-details .left-box {
  padding-right: 15px;
}

.card-content.support-card .contact-details .right-box {
  padding-left: 15px;
  text-align: right;
}

.contact-details .left-box p.name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #070707;
  margin-bottom: 12px;
}

.contact-details .left-box p.text {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #6d6d6d;
  margin-bottom: 4px;
}

.contact-details .left-box p.text a {
  font-size: 12px;
  line-height: 14px;
  color: #6d6d6d;
}

.contact-details .right-box p.ticketid {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2e6adf;
  margin-bottom: 10px;
}

.contact-details .right-box p.date {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #717171;
  margin-bottom: 10px;
}

.contact-details .right-box p.tags {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tags .enquiry {
  background: #dbe7ff;
}

.tags .unattended {
  background: #ffe8bb;
}

.tags > span {
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: center;
  color: #333333;
  margin-left: 7px;
  padding: 5px;
  border-radius: 3px;
}
.list-section {
  padding: 1.5rem;
  margin: 0;
  width: 100%;
}

.list-section .left-box .select-box {
  margin-top: 0px !important;
  margin-bottom: 28px !important;
}

.list-section .list-box {
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(248 248 248 / 25%);
  border-radius: 3px;
  padding: 20px;
}

.list-section .list-box ul.content-list {
  margin: 0;
  padding: 0;
}

.list-section .list-box ul.content-list li {
  list-style: none;
  margin-bottom: 28px;
}

.list-section .list-box ul.content-list li p.main {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #555555;
  margin-bottom: 8px;
}

.list-section .list-box ul.content-list li p.text {
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  /* or 17px */
  color: #a49e9e;
  margin: 0;
}

.list-section .left-box {
  display: flex;
  flex-direction: column;
  padding-right: 30px !important;
}

.list-section .right-box {
  background: #ffffff;
  border: 1px solid #e9e9e9;

  /* new */
  box-shadow: 4px 4px 15px rgba(217, 217, 217, 0.25);
  border-radius: 5px;
  padding: 0;
  border-bottom: 1px solid #ebebeb;
}

.list-section .right-box .card-header {
  padding: 25px 20px;
  margin-bottom: 32px;
}

.list-section .right-box .comment-list {
  padding: 0 20px 32px;
}

.list-section .right-box .add-comment {
}

.list-section .right-box .card-header h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  color: #2e6adf;
  margin: 0;
}

.list-section .right-box .comment-list ul.comments {
  margin: 0;
  padding: 0;
  position: relative;
}

.list-section .right-box .comment-list ul.comments li {
  list-style: none;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.list-section .right-box .comment-list ul.comments li p.name {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #636363;
  margin-bottom: 4px;
  display: inline-block;
  width: 100%;
}

.list-section .right-box .comment-list ul.comments li p.text {
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  /* or 17px */
  color: #a49e9e;
  margin-bottom: 0;
  display: inline-block;
  width: 100%;
}

.list-section .right-box .comment-list ul.comments li a.link {
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height, or 17px */
  color: #86afff;
}

.list-section .right-box .comment-list ul.comments li ul.comments {
  padding-left: 45px;
  margin-top: 12px;
}

.list-section .right-box .comment-list ul.comments li p.name span {
  font-weight: normal;
  font-size: 9px;
  line-height: 140%;
  /* identical to box height, or 13px */
  color: #828181;
  margin-left: 4px;
}

.list-section .right-box .comment-list ul.comments li:last-child {
  margin-bottom: 0 !important;
}

.list-section .right-box .comment-list ul.comments li ul.comments:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 0;
  width: 1px;
  height: 100%;
  background: #dddddd;
}

.list-section .right-box .add-comment .position-relative {
  display: flex;
  padding: 20px;
}

.list-section .right-box .add-comment .form-control {
  padding: 12px 0;
  border: none;
  border-bottom: 1px solid #dedede !important;
  margin-right: 30px;
  border-radius: 0;
}

.list-section .right-box .add-comment .btn.btn-primary {
  background: #2e6adf;
  border-radius: 1.70588px;
  padding: 6px;
  width: 40px;
  height: 40px;
}
.row.m-top {
  margin-top: 16px;
}
.textarea-height textarea.form-control {
  height: 107px !important;
  padding: 7px 8px !important;
}
.companylist-card {
  border: 1px solid rgb(220, 220, 220);
  box-shadow: 0px 4px 3px rgba(232, 232, 232, 0.09);
  border-radius: 5px;
  margin-bottom: 0;
}

.companylist-card .list-header {
  padding: 12px 16px;
  background: #fafafa;
  border-radius: 6px;
}

.companylist-card .list-header h4.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #313131;
  margin: 0;
}

.companylist-card .company-list-items {
}

.companylist-card .company-list-items ul {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.companylist-card .company-list-items ul li {
  list-style: none;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.companylist-card .company-list-items ul li .sub {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #909090;
  /* min-width: 160px; */
  display: inline-block;
}

.companylist-card .company-list-items ul li .text {
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #636363;
  flex: 1;
  word-break: break-all;
  padding-left: 8px;
}
.companylist-card .company-list-items .table-block {
  margin: 0 !important;
  width: 100%;
  padding: 0 15px;
}

.companylist-card .company-list-items .table-block .table.custom-table {
  margin-bottom: 14px;
  border: 1px solid #f1f1f1 !important;
}

.companylist-card .company-list-items .table-block .table.custom-table th {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #313131;
  background: #f4f4f4;
  padding: 10px !important;
  border: 1px solid rgb(214, 216, 220);
  border-right: 0;
}
.companylist-card
  .company-list-items
  .table-block
  .table.custom-table
  th:last-child {
  border-right: 1px solid rgb(214, 216, 220);
}

.companylist-card .company-list-items .table-block .table.custom-table td {
  padding: 15px 10px;
  background: #ffffff;
  border-bottom: 1px solid #f1f1f1;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
  vertical-align: middle;
  background: center;
}

.companylist-card .company-list-items .table-block .table.custom-table td a {
  background: #2e6adf;
  color: #fff;
  padding: 2px 4px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 4px;
}
.companylist-card .company-list-items ul.flex-25 {
  margin-bottom: 10px;
  flex: 0 0 25%;
  max-width: 25%;
}
.edit-form .upload-block.upload-block01 .btn.btn-primary {
  margin: 8px 0 0;
}
