.swal2-container {
  z-index: 20000 !important;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #ccc !important;
}
.success-button-padding{
  padding: 7px;
}
