.checkbox-card {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 4px 4px rgba(225, 225, 225, 0.25);
    border-radius: 2px;
    padding: 20px 30px;
}

.checkbox-card .card-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid #E1E1E1;
}

.checkbox-card .card-title > p {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
/* identical to box height */
    color: #555555;
    margin: 0;
    width: calc(100% / 4);
    text-align: center;
}

.checkbox-card .card-body.checkbox-block {
    padding: 15px  0 0px;
}

.checkbox-card .card-body.checkbox-block .card-row {
 
    margin: 0 !important;
}

.checkbox-card .checkbox-block  {
    margin-bottom: 7.5px;
    margin-top: 7.5px;
    margin-right: 0 !important;
    text-align: center;
}

.checkbox-card .checkbox-block label:before {
    border-radius: 1.5px;
    width: 10px;
    height: 10px;
    background: rgba(134, 131, 131, 0.24);
    border: none !important;
    padding: 6px;
}

.checkbox-card .checkbox-block input:checked + label:before,.checkbox-card .checkbox-block input:checked + label:after {
    background: #2E6ADF;
}

.checkbox-card .checkbox-block input:checked + label:after {
    border-radius: 0px;
    top: 3px;
    left: 1px;
}

.checkbox-card .checkbox-block label {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
/* identical to box height */
    color: #555555;
}

.checkbox-card .checkbox-block:last-child {
    
}
.checkbox-card .checkbox-block {
    display: flex;
    align-items: center;
    justify-content: center;
}