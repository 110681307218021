.main-menu.menu-fixed {
  position: fixed;
  height: 100%;
  top: 80px;
  height: calc(100% - 80px);
  overflow-y: auto;
}
.main-menu.menu-light #main-menu-navigation > li > a {
  padding: 20px 26px;
  font-weight: normal;
  font-size: 13px;
  color: #fff;
}

body.vertical-layout.vertical-menu.menu-expanded
  .main-menu
  #main-menu-navigation
  > li
  > a
  > i.menu-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 18px;
  min-width: 20px;
}
.main-menu.menu-light #main-menu-navigation > li.active span.menu-title,
.main-menu.menu-light #main-menu-navigation > li a.active span.menu-title {
  color: #2e6adf;
}
.main-menu.menu-light #main-menu-navigation > li > a:before,
.main-menu.menu-light #main-menu-navigation > li > a:before {
  /* content: "";
  position: absolute;
  background: #2e6adf;
  width: 0px;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 0.2s ease; */
}

.main-menu.menu-light #main-menu-navigation > li > a {
  position: relative;
}

.main-menu.menu-light #main-menu-navigation > li.active > a:before,
.main-menu.menu-light #main-menu-navigation > li > a:hover:before,
.main-menu.menu-light #main-menu-navigation > li > a:focus:before,
.main-menu.menu-light #main-menu-navigation > li > a.active:before {
  /* content: "";
  width: 6px;
  transition: all 0.2s ease; */
}

.main-menu.menu-light #main-menu-navigation > li > a:hover .menu-title,
.main-menu.menu-light #main-menu-navigation > li > a:focus .menu-title {
  color: #313131 !important;
  font-weight: normal !important;
}

.main-menu.menu-light
  #main-menu-navigation
  > li.active
  a:hover
  span.menu-title {
  font-weight: normal !important;
}
.main-menu.menu-light #main-menu-navigation > li.active > a,
.main-menu.menu-light #main-menu-navigation > li > a:hover,
.main-menu.menu-light #main-menu-navigation > li > a:focus {
  /* background: #f8f8f8; */
}
.bg-info {
  background-color: #ffffff !important;
}

.main-menu.menu-shadow {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.04);
}
.main-menu.menu-light #main-menu-navigation > li.hover > a > i,
.main-menu.menu-light #main-menu-navigation > li:hover > a > i,
.main-menu.menu-light #main-menu-navigation > li.active > a > i {
  transform: translateX(0px);
}
.main-menu.menu-light #main-menu-navigation > li.hover > a > span,
.main-menu.menu-light #main-menu-navigation > li:hover > a > span,
.main-menu.menu-light #main-menu-navigation > li.active > a > span {
  transform: translateX(0px);
}
.main-menu.menu-light #main-menu-navigation > li.active > a {
  font-weight: normal !important;
}
body.vertical-layout.vertical-menu.menu-collapsed
  .main-menu
  .main-menu-content
  > span.menu-title,
body.vertical-layout.vertical-menu.menu-collapsed
  .main-menu
  .main-menu-content
  a.menu-title {
  display: none !important;
}
body.vertical-layout.vertical-menu.menu-collapsed
  .main-menu
  #main-menu-navigation
  > li
  > a
  > .menu-icon {
  background-repeat: no-repeat;
}

body.vertical-layout.vertical-menu.menu-collapsed
  .main-menu
  #main-menu-navigation
  > li
  > a
  > i.menu-icon.ic-digitalmanagement {
  height: 26px;
  top: 6px !important;
  position: relative;
}
.main-menu.menu-light #main-menu-navigation > li > a .ic-digitalmanagement {
  flex: 0 0 18px;
  max-width: 18px;
}

.main-menu.menu-light #main-menu-navigation > li > a {
  display: flex;
  width: 100%;
  align-items: center;
}

.main-menu.menu-light #main-menu-navigation {
  display: flex;
  flex-direction: column;
}

.main-menu.menu-light #main-menu-navigation > li {
  display: flex;
  padding: 0 !important;
  border-radius: 6px;
  max-height: 36px;
}

.main-menu.menu-light #main-menu-navigation li a span {
  display: flex;
  word-break: break-word;
}

body.vertical-layout.vertical-menu.menu-collapsed
  .main-menu
  #main-menu-navigation
  > li
  > a {
  min-height: 50px;
  transition: all 0.3s ease;
}

.main-menu.menu-light #main-menu-navigation > li > a i.menu-icon {
  top: 0 !important;
}

body.vertical-layout.vertical-menu.menu-collapsed
  .main-menu
  #main-menu-navigation
  > li
  > a
  > i.menu-icon.ic-digitalmanagement {
  top: 3px !important;
}

.main-menu.menu-fixed::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0px;
  background-color: transparent;
}

.main-menu.menu-fixed::-webkit-scrollbar {
  width: 1px;
  height: 8px;
  background-color: #fff;
}

.main-menu.menu-fixed::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #ddd;
}
body.vertical-layout.menu-collapsed #main-menu-navigation a {
  padding: 0;
  width: 60px;
  justify-content: center;
}

body.vertical-layout.menu-collapsed #main-menu-navigation a i.menu-icon {
  margin: 0;
}

body.vertical-layout.menu-collapsed #main-menu-navigation a .menu-title {
  transform: scale(0);
  position: absolute;
  transition: all 0.1s ease;
  transform-origin: left;
}

body.vertical-layout #main-menu-navigation a .menu-title {
  /* position: absolute;
  left: 60px;
  transition: all 0.1s ease;
  transform: scale(1);
  display: block !important;
  width: 175px;
  transform-origin: left; */
  flex: 1;
}

body.vertical-layout.vertical-menu .main-menu #main-menu-navigation > li > a {
  /* min-height: 61px; */
  transition: all 0.3s ease;
}

@media (max-width: 1370px) {
  body.vertical-layout.vertical-menu .main-menu #main-menu-navigation > li > a {
    min-height: 51px;
    transition: all 0.3s ease;
  }
  .main-menu.menu-light #main-menu-navigation > li > a {
    font-size: 12px;
  }
}
.menuLink {
  justify-content: space-between;
  gap: 8px;
  padding: 8px !important;
}
body.vertical-layout.vertical-menu .main-menu {
  width: 64px !important;
  background: #000;
  padding: 0 14px;
}

body.vertical-layout.vertical-menu .main-menu:hover {
  width: 240px !important;
}

body.vertical-layout.vertical-menu .main-menu a.menuLink {
  flex-wrap: nowrap;
  border-radius: 6px;
  width: 208px !important;
  max-height: 36px;
}

body.vertical-layout.vertical-menu .main-menu ul {
  padding: 0;
  margin-top: 16px;
  gap: 4px;
  overflow: hidden;
  white-space: nowrap;
}

body.vertical-layout.vertical-menu
  .main-menu
  li.active
  a.menuLink
  span.menu-title,
body.vertical-layout.vertical-menu .main-menu a.menuLink:hover .menu-title {
  color: #313131 !important;
}

body.vertical-layout.vertical-menu .main-menu a.menuLink:hover {
  background: transparent !important;
}

body.vertical-layout.vertical-menu .main-menu li:hover,
body.vertical-layout.vertical-menu .main-menu li.active {
  background: #fff !important;
}
body.vertical-layout.vertical-menu .main-menu li:hover span.menu-title,
body.vertical-layout.vertical-menu .main-menu li.active span.menu-title {
  color: #313131 !important;
}

body.vertical-layout.vertical-menu .main-menu li:hover svg,
body.vertical-layout.vertical-menu .main-menu li.active svg {
  color: #313131;
}
