.customer-list-head {
  background: #fafcff;
  border-radius: 3px;
  padding: 20px 25px;
}

.customer-list-head + .card-header {
  padding-top: 0;
}

.customer-list-head span.details {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #3e3e3e;
  position: relative;
}

.customer-list-head .link {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #313131;
}

.customer-list-head .text-left span.details {
  padding-right: 12px;
  margin-right: 12px;
}

.customer-list-head .text-left span.details:before {
  content: "";
  position: absolute;
  right: 0;
  top: 10%;
  background: #3e3e3e;
  width: 1px;
  height: 80%;
}

.customer-list-head .text-left span.details:last-child:before {
  background: transparent;
}
.edit-form .upload-block {
  flex: 0 0 135px;
}
.create-content .upload-img {
  background: #ffffff;
  border: 0.6px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(225 225 225 / 25%);
  border-radius: 2px;
  width: 135px;
  height: 135px;
  padding: 7px;
}
.customer-list {
  margin-bottom: 20px;
}

.customer-list .details {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #3e3e3e;
}
.kyc-left {
  margin-right: 20px;
  border-right: 1px solid #e8e8e8;
  max-width: 20%;
}

.kyc-right {
  flex: 1;
}

.kyc-left label {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #555555;
  margin: 0;
  cursor: pointer;
}

.kyc-left .col-md-12.col-xs-12 {
  margin-bottom: 20px;
}

.kyc-left input {
  position: relative;
  top: 2px;
  margin-right: 6px;
  cursor: pointer;
}
