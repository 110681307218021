.edit-form .text-left .btn.btn-primary {
  margin-left: 0;
}
.upload-field label {
  width: 100%;
  cursor: pointer;
  position: relative;
  height: 32px !important;
  box-sizing: border-box;
  padding: 0 8px !important;
  background: #ffffff !important;
  border: 1px solid #afafaf !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 21px !important;
  margin: 0 !important;
}

.upload-field input[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.flex-display.d-flex {
  align-items: center;
}

.flex-display.d-flex .form-control {
  width: auto;
  flex: 1;
  margin: 0;
}

.flex-display.d-flex label.control-label {
  margin-right: 16px;
  margin-bottom: 0;
}
.pricing-box {
  margin-top: 0;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 4px 4px rgba(239, 239, 239, 0.25);
  border-radius: 6px;
  padding: 16px;
}

.pricing-box .sub-head p {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #313131;
  margin-bottom: 8px;
}

.pricing-box .text {
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #313131;
  margin-bottom: 12px;
}

.pricing-box label.control-label.sub-head {
  margin-bottom: 12px;
}

.pricing-box table.table {
  border: 1px solid #e1e1e1;
}

.pricing-box table.table tr td {
  border: 1px solid #dcdcdc;
  border-top: none;
  color: #313131;
  font-size: 13px;
  padding: 8px 10px;
}

.pricing-box .col-md-3.col-xs-6 {
  position: relative;
}

.pricing-box .col-md-3.col-xs-6:before {
  content: "";
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
}

.pricing-box .col-md-3.col-xs-6:after {
  content: "";
  background: #dcdcdc;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
}
.row.align-center {
  align-items: center;
}

.text-left .btn {
  margin-left: 0 !important;
}
.border-lr {
  position: relative;
}

.border-lr:before {
  content: "";
  background: #e4e4e4;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  position: absolute;
}

.border-lr:after {
  content: "";
  background: #e4e4e4;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  position: absolute;
}

.row.align-start {
  align-items: flex-start;
}

.border-l {
  position: relative;
}

.border-l:before {
  content: "";
  background: #e4e4e4;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  position: absolute;
}
.row.align-end {
  align-items: flex-end;
}
.pricing-box .btn.btn-secondary {
  margin-bottom: 0;
}
.pricing-box.m-o {
  margin-top: 0;
}
.pricing-box.m-o .col-md-6.col-xs-12 {
  margin-bottom: 28px;
}

.pricing-box.m-o .col-md-12.col-xs-12.text-left .btn {
  margin: 0 !important;
}

.pricing-box.m-o .col-md-6.col-xs-12 .form-control {
  margin-bottom: 16px;
}
.row.align-end a.delete {
  position: relative;
  top: -6px;
}
